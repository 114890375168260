.ant-list-item-action {
  margin-left: 0px !important;
}

.ant-avatar-lg {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
}

.ant-list-item-meta-title {
  margin-top: 6px;
}

.ant-list-item-no-flex {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.notification-item {
  position: relative;
}

.notification-item .ant-alert-close-icon {
  margin-top: -10px;
  margin-right: -10px;
  margin-left: 0;
  z-index: 1;
}

.notification-item .ant-alert-close-icon span {
  padding: 10px;
}

.notification-item-link {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}
