.dashboard-page {
    margin: 0px -22px 0px;
    min-height: 100vh;
    padding: 24px;
}

.dashboard-page-card {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08) !important;
    border-radius: 6px !important;
    background: #fff !important;
    overflow: hidden !important;
}

.dashboard-page-card > .ant-card-body {
    padding: 0 !important;
}

.dashboard-page__tabs .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.dashboard-notifications {
    display: block;
    height: min(500px, 60vh);
    overflow-x: auto;
}

.changelog {
    position: relative;
    top: -5px;
    padding-left: 5px;
    font-size: 14px;
}

.changelog code {
    margin: 0 1px;
    padding: .2em .4em;
    font-size: .9em;
    background: #f2f4f5;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
}