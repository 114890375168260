/** Менеджер списков */
.list-manager {
    display: block;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

.list-manager__overlay {
    box-shadow: 0px 0px 0px 10px #f5f5f5;
    padding: 24px;
    /* removing cuttings */
}

.list-manager td {
    vertical-align: middle;
}

/** Табличный вид */
.list-manager--table-mode {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}

.list-manager--table-mode .ant-page-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.list-manager--table-mode .list-manager__footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

/** Карточный вид */
.list-manager--tiles-mode .ant-page-header,
.list-manager--tiles-mode .list-manager__footer {
    border-radius: 6px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08);
}

.list-manager--tiles-mode .ant-page-header {
    margin-bottom: 14px;
}

/** Шапка */
.list-manager .ant-page-header {
    padding-bottom: 0;
}

.list-manager .ant-tabs-nav {
    margin-bottom: 0 !important;
}

/** Ноги */
.list-manager__footer {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    background-color: #fff;
    border-top: 1px solid #f0f0f0;
    padding: 8px 24px;
}

/** Пагинация */
.list-manager__pagination {
    margin-left: auto !important;
}

/** Кнопка преезагрузки данных */
.list-manager__refetch-button {
    font-size: 12px;
}

/** Заголовок */
.list-manager__title {
    display: inline-block;
    font-size: 1.37rem;
}

/** Форма поиска */
.list-manager-search__icon {
    color: var(--main-color);
    margin-right: 5px;
}

/** Фильтр */
.list-manager-filter {
    display: block;
    margin: 10px 0;
}

/** Фильтр : Кнопка */
.list-manager-filter__button {
    margin-right: 7px;
}

.list-manager-filter__button > * {
    vertical-align: middle;
}

/** Фильтр : Превью */
.list-manager-filter__preview {
    display: inline-block;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/** Фильтр : Checkbox */
.list-manager-checkbox * {
    --antd-wave-shadow-color: var(--color, var(--main-color));
}

.list-manager-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.list-manager-checkbox .ant-checkbox:hover,
.list-manager-checkbox .ant-checkbox-checked::after {
    border-color: var(--color, var(--main-color)) !important;
}

.list-manager-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color, var(--main-color)) !important;
    border-color: var(--color, var(--main-color)) !important;
}

/** Фильтр : Тег */
.list-manager-filter-tag {
    --opacity: 0;
    position: relative;
    display: inline-block;
    color: var(--color, #000);
    margin: 0 4px 8px 4px;
    padding: 4px 9px;
    border-radius: 3px;
    border: 1px solid var(--color, #000);
    cursor: pointer;
    transition: all 0.3s;
}

.list-manager-filter-tag span {
    position: relative;
    z-index: 2;
}

.list-manager-filter-tag--checked {
    --opacity: 1;
    color: #ffffffeb !important;
}

.list-manager-filter-tag::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: var(--color);
    opacity: var(--opacity);
    transition: all 0.3s;
}

/** Пользователь */
.widget-user {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.widget-user__name {
    display: inline-block;
    max-width: 125px;
    margin: 0 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.widget-user-select {
    padding: 1px 2px;
}

.widget-user-select .ant-list-item-meta-avatar {
    margin-right: 7px;
}

.widget-user-select .ant-list-item-meta-title {
    margin-bottom: 0;
}

.widget-user-select .ant-list-item-meta-description {
    margin-top: -4px;
}

/** Модифицаторы */
.list-manager .align-center {
    text-align: center;
}

.list-manager .cursor-pointer {
    cursor: pointer;
}

.MuiSvgIcon-root {
    vertical-align: middle;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 1rem !important;
}

.icon--mr {
    margin-right: 5px;
}