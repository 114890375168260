:local {
    .counterIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 5px;
        position: relative;
        background-color: var(--color);
        
       
    
        & > :global(span) {
            color: #fff;
            font-size: 30px;
        }
    
    }


    .counterIconT {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 5px;
        position: relative;
        background-color: var(--primary-color);
        
       
    
        & > :global(span) {
            color: #fff;
            font-size: 30px;
        }
    
    }
}

.statCard {
    .ant-pro-card-title {
        color: #fff !important
    }

    span, .ant-statistic-title {
        color: #fff
    }
}