.list-tile {
    --padding: 18px;
    --border-radius: 6px;
    --galery-width: 185px;
    --border-color: #d9d9d9;
    --overview-wrap: 30px;

    display: flex;
    background-color: #fff;
    border: 1px solid var(--border-color);
    padding: var(--padding);
    border-radius: var(--border-radius);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08);

    margin-bottom: 14px;
    margin-top: 7px;
}

/** Блок галереи апартаментов */
.list-tile__galery {
    width: var(--galery-width);
    margin-right: var(--padding);
}

.list-tile__galery--photo {
    width: var(--galery-width);
    height: var(--galery-width);
    object-fit: cover;
    border-radius: var(--border-radius);
    color: rgba(0, 0, 0, 0)
}

/** Контент */
.list-tile__content {
    display: grid;
    width: 100%;

    grid-template-columns: [content] auto [aside] 160px;
    grid-template-rows: [actions] 22px [main] auto [overview] auto;
    grid-gap: calc(var(--padding) / 2);
}

/** Статусы */
.list-tile__statuses {
    grid-column: content;
    grid-row: actions;
}

/** Действия */
.list-tile__actions {
    grid-column: aside;
    grid-row: actions;
    text-align: right;
}

/** Основной блок */
.list-tile__main {
    grid-column: content;
    grid-row: main;
}

/** Заголовок */
.list-tile__title {
    margin-bottom: 0.2em !important;
}

/** Локация */
.list-tile__location {
    font-size: 0.8rem;
    color: rgb(255, 3, 80)
}

.list-tile__location svg {
    margin-bottom: -5px;
    font-size: 1.3rem;
    vertical-align: unset !important;
}

/** Стоимость */
.list-tile__cost {
    grid-column: aside;
    grid-row: main;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.list-tile__cost--month {
    font-size: 1.3em;
}

.list-tile__cost--utilities {
    font-size: 1.1em;
}

/** Сводка */
.list-tile__overview {
    grid-column: content / 3;
    grid-row: overview;
    margin-top: auto;
}

.list-tile__overview::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--border-color);
    margin-bottom: calc(var(--padding) / 2);
}

.list-tile-overview__list {
    display: flex;
}

.list-tile-overview__item {
    padding-left: calc(var(--overview-wrap) / 2);
}

.list-tile-overview__item:first-child {
    padding-left: 0;
}

.list-tile-overview__item::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 17px;
    background: var(--border-color);
    margin-left: calc(var(--overview-wrap) / 2);
}

.list-tile-overview__item:last-child::after {
    display: none;
}

.list-tile-overview__item > span > svg {
    vertical-align: middle;
    margin-left: calc(var(--overview-wrap) / 4);
}