:local {
    .layout {
      min-height: 100vh !important;
  
      :global(.project--landlords) & {
          --logo-height: 40px;
      }
  
      :global(.project--tenants) & {
          --logo-height: 60px;
      }
    }

    
  
    .content {
      background-color: var(--background-color) !important;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 30px 30px;
      scroll-behavior: smooth;
      will-change: contents;
    }
  
    .logo {
      height: var(--logo-height);
      width: 100%;
      object-fit: contain;
      object-position: center left;
      margin-top: 24px;
    }
  
    .sider {
      border-right: 1px solid #f0f0f0;
      position: relative;
      height: 100vh;
      width: 100%;

      :global(.ant-menu) {
        margin-top: 15px;
        border-right: 0 !important;
        
        svg {
          width: 1.5em !important;
          height: 1.5em !important;
          vertical-align: -4px;
        }
      }
  
      :global {
        .ant-menu-item {
          border-radius: 7px;
          padding: 0 13px !important;
          margin: 0 13px;
          width: calc(100% - 24px) !important;
  
          &::after {
            display: none !important;
          }
        }
  
        .ant-menu-item-selected {
          background-color: var(--primary-color) !important;
          background-image: linear-gradient(62deg, rgba(0, 0, 0, .1) 25%, rgba(0, 0, 0, 0) 100%);
          color: #fff !important;
        }
  
        .ant-menu-item-selected a {
          color: #fff !important;
        }

        .ant-menu-item-selected .ant-badge-count {
          color: #999;
          background-color: #fff;
          box-shadow: 0 0 0 1px #d9d9d9 inset;
          margin-left: 5px;
        }
      }
  
      &:global(.ant-layout-sider-collapsed .ant-menu-item) {
        text-align: left;
        padding-left: 16px !important;
      }
    }

    .siderGrid {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      max-height: 100vh;
      overflow-x: scroll;

      scrollbar-width: none;
      &::-webkit-scrollbar {
          display: none;
      }
    }
  
    .collapseSider {
      --width: 17px;
  
      position: absolute !important;
      z-index: 3;
      top: 50%;
      right: calc(calc(var(--width) - 1px) * -1);
      width: var(--width);
      background-color: #fff !important;
      border: 1px solid #f0f0f0 !important;
      border-left-width: 0 !important;
      padding: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
  
      & > span {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
  
        & > svg {
          display: inline-block;
        }
      }
  
      :global(svg) {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  
    .siderHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(15px + var(--logo-height) + calc(60px - var(--logo-height)));
      padding: 0 9px;
    }
  
    .languagePicker {
      text-align: center;
      margin-top: auto;
      padding-bottom: 9px;
  
      :global(.ant-btn) {
        transition: 
          background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
        opacity: .8;
      }
  
      &:hover :global(.ant-btn) {
        opacity: 1; 
      }
    }
}
  
  