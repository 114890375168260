:local {
    @offset: 17px;

    .form {
        & > :global(.ant-form-item) {
            margin-bottom: @offset !important;
        }
    }

    .navigation {
        display: block;
        text-align: center;
        margin: @offset 0;
    }

    .illustration {
        display: block;
        object-fit: contain;
        width: 100%;
        max-width: 310px;
        height: 100%;
        margin: var(--illustration-margin) auto 25px auto;
    }

    .verify {
      background-color: rgba(0,0,0,.02);
      border: 1px solid #f0f0f0;
      padding: 10px 7px 7px 7px !important;
      border-radius: 4px;
    }

    .verifyContainer {
      margin-bottom: 15px;
      justify-content: center;

      & > div {
        margin-right: 5px;

        &:nth-child(3) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      input {
        width: 2em !important;
        padding: 4px 3px;
        caret-color: var(--primary-color);
        color: var(--primary-color);
      }
    }

    .addition {
        display: flex;
        justify-content: space-between;

        > a {
            margin-top: 8px;
        }
    }
}
