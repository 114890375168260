:local {
  .panel {
    display: flex;
    justify-content: center;
    height: 44px;
    padding: 0 13px;
    margin: 0;
    border-width: 0;
  }

  .panelContent {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }
  
  .panelName {
    display: block;
    max-width: 148px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: -5px;
  }

  .panelRole {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.75rem;
  }

  .panelAvatar {
    border: 3px solid #fff;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    min-width: 42px;

    :global(.ant-avatar-string) {
      line-height: 37px !important;
    }
  }
  .panelActions {
    margin-left: auto;
  }

  .panel[data-type="short-panel"] .panelActions {
    position: absolute;
    right: 8px;
  }
}
