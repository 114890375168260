.dashboard-page {
    margin: -30px -30px -50px;
    min-height: 100vh;
    padding: 24px;
}

.dashboard-page-card {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.11), 0 3px 4px rgba(0, 0, 0, 0.08) !important;
    border-radius: 6px !important;
    background: #fff !important;
    overflow: hidden !important;
}

.dashboard-page-card > .ant-card-body {
    padding: 0 !important;
}

.dashboard-page__tabs .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.dashboard-notifications {
    display: block;
    height: min(500px, 60vh);
    overflow-x: auto;
}