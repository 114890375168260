:local {
    .box {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 10px;
        background-color: #fafafa;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .boxInfo {
        margin-left: 10px;
        margin-right: 5px;
        & > * {
            display: block;
        }
    }

    .boxRole {
        font-size: 0.6rem;
    }

    .arrow {
        margin-left: auto;
    }

    .item {
        display: flex;
        align-items: center;
        height: 32px;

        & > :global(.ant-typography) {
            margin-right: 5px;
        }
    }
}